/**
 * Simplebar
 */

(function (window) {
  function initScroll() {
    const dataName = '[data-customscrollbar]';
    const scrolls = Array.from(document.querySelectorAll(dataName));
    const options = { autoHide: false };
    const alpineSubTrees = [];

    for (let scroll of scrolls) {
      const simpleBar = new SimpleBar(scroll, options);

      // Prevent huge gaps in FF
      simpleBar.recalculate();

      // prep to init Alpine by removing the x-ignore flag
      const alpineIgnored = scroll.closest('[x-ignore]');
      if (alpineIgnored) {
        alpineIgnored.removeAttribute('x-ignore');
        alpineSubTrees.push(alpineIgnored);
      }
    }

    // actually init Alpine-subtrees after some delay
    // possibly to account for the DOM (and Alpine)
    // to come to rest after all mutations.
    if (window.Alpine && 'initTree' in window.Alpine) {
      window.setTimeout(function () {
        alpineSubTrees.forEach((r) => window.Alpine.initTree(r));
      }, 100);
    }
  }

  initScroll();
})(this);
