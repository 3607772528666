// Init animations

(function (window) {
  function initAnimations() {
    function returnPxHeight(el) {
      return Math.floor(el.scrollHeight) + 'px';
    }

    function animateOnImageLoad(element) {
      // Look for images and check that lazysizes has not yet loaded them
      const imagesNotLoaded = Array.from(
        element.querySelectorAll('img')
      ).filter((img) => !img.classList.contains('lazyloaded'));

      // Wait for Promise to resolve with all images lazyloaded, and then animate
      Promise.all(
        imagesNotLoaded.map(
          (img) =>
            new Promise(function (resolve, reject) {
              img.addEventListener('lazyloaded', resolve);
            })
        )
      ).then(() => animateElement(element));
    }

    function animateElement(element) {
      const elementClasses = element.classList;
      if (elementClasses.contains('animate-reveal-to-bottom')) {
        const hasWrapper = !('animateNoWrapper' in element.dataset);
        const wrapper = hasWrapper && document.createElement('div');
        const maxHeight = returnPxHeight(element);

        // Create fixed height wrapper to avoid expanding element shifting the layout
        if (hasWrapper) {
          wrapper.style.height = element.clientHeight + 'px';
          wrapper.style.width = element.clientWidth + 'px';
        }

        element.style.width = element.clientWidth + 'px';
        element.style.setProperty('position', 'absolute');
        hasWrapper &&
          element.parentNode.insertBefore(wrapper, element.nextSibling);

        // Declare height to animate to via dynamic css custom property
        element.style.height = maxHeight;
        element.style.maxHeight = 0;
        returnPxHeight(element);
        element.style.setProperty('--max-height', maxHeight);

        // Trigger animation
        element.classList.add('animate');

        // Reset properties once transition is complete
        element.addEventListener('transitionend', function (event) {
          if (event.propertyName == 'max-height') {
            hasWrapper && wrapper.parentNode.removeChild(wrapper);
            element.style.maxHeight = '';
            element.style.height = '';
            element.style.width = '';
            element.style.removeProperty('--max-height');
            element.style.removeProperty('position');
          }
        });
      } else if (elementClasses.contains('animate-zoom-out')) {
        element.classList.add('animate');
      }
    }

    function animateOnLoad() {
      const selector = '[data-animate="on-load"]';
      const elements = Array.from(document.querySelectorAll(selector));

      for (let element of elements) {
        animateOnImageLoad(element);
      }
    }

    function animateOnScroll() {
      const selector = '[data-animate="on-scroll"]';
      const elements = Array.from(document.querySelectorAll(selector));

      elements.forEach((element) => {
        const threshold = element.dataset.animateThreshold;
        let options = {
          threshold: threshold ? threshold : 0.8,
        };

        const observeScroll = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              animateOnImageLoad(entry.target);
              // stop observing
              observeScroll.unobserve(entry.target);
            }
          });
        }, options);

        observeScroll.observe(element);
      });
    }

    animateOnLoad();
    animateOnScroll();
  }

  initAnimations();
})(this);
