/**
 * Reel.js
 *
 * Add a class to hook into for additional styling when container
 * is overflowing. Usually used in combination with custom scrollbars.
 *
 * https://every-layout.dev/layouts/reel/
 */

(function (window) {
  // detect support for ResizeObserver & MutationObserver
  if (!('ResizeObserver' in window && 'MutationObserver' in window)) {
    return;
  }

  // create observers
  const resizeXObserver = new ResizeObserver(toggleOverflowClassWidth);
  const mutationXObserver = new MutationObserver(toggleOverflowClassWidth);
  const resizeYObserver = new ResizeObserver(toggleOverflowClassHeight);
  const mutationYObserver = new MutationObserver(toggleOverflowClassHeight);

  // class toggles based on width / height
  function toggleOverflowClassWidth(entries) {
    entries.forEach((entry) =>
      entry.target.classList.toggle(
        'overflowing',
        entry.target.scrollWidth > entry.target.clientWidth
      )
    );
  }
  function toggleOverflowClassHeight(entries) {
    entries.forEach((entry) =>
      entry.target.classList.toggle(
        'overflowing',
        entry.target.scrollHeight > entry.target.clientHeight
      )
    );
  }

  // init
  function initReelX() {
    const className = 'reel';

    resizeXObserver.disconnect();
    mutationXObserver.disconnect();

    Array.from(document.querySelectorAll(`.${className}`)).forEach((reel) => {
      resizeXObserver.observe(reel);
      mutationXObserver.observe(reel, { childList: true });
    });
  }

  function initReelY() {
    const className = 'reel-y';

    resizeYObserver.disconnect();
    mutationYObserver.disconnect();

    Array.from(document.querySelectorAll(`.${className}`)).forEach((reel) => {
      resizeYObserver.observe(reel);
      mutationYObserver.observe(reel, { childList: true });
    });
  }

  initReelX();
  initReelY();
})(this);
