// Init Lightboxes
// uses: https://github.com/electerious/basicLightbox

(function (window) {
  function initLightboxes(
    selector = '[data-lightbox-show]',
    datasetAttribute = 'lightboxShow',
  ) {
    var dialogs = document.querySelectorAll(selector);

    if (dialogs.length > 0) {
      dialogs.forEach(function (trigger) {
        var source = document.querySelector(trigger.dataset[datasetAttribute]);

        if (source) {
          var lightbox = basicLightbox.create(source, {
            onShow: bodyScrollLock.disableBodyScroll,
            onClose: bodyScrollLock.enableBodyScroll,
          });
          var closeTriggers = lightbox
            .element()
            .querySelectorAll('[data-lightbox-close]');
          trigger.onclick = function (e) {
            e.preventDefault();
            lightbox.show();
          };
          closeTriggers.forEach(function (close) {
            close.onclick = lightbox.close;
          });
        }
      });
    }

    // clear all scroll locks
    bodyScrollLock.clearAllBodyScrollLocks();
  }

  function initPDFLightboxes() {
    'use strict';
    if ('pdfViewerEnabled' in navigator && navigator.pdfViewerEnabled) {
      // Pass the dataset attribute for PDF lightboxes
      initLightboxes('[data-lightbox-show-pdf]', 'lightboxShowPdf');
    }
    initLightboxes('[data-lightbox-show]');
  }

  initPDFLightboxes();
})(this);
